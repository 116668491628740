import React, { useContext, useEffect } from "react";
import DataCard from "../../components/DataCard/DataCard";
import { ProjectContext } from "../../context/Hours";
import { getProjectHours, getSubProjectHours } from "../../core/api";
import { HttpRequestStatus, statusNull } from "../../utils/http";

const Overview = () => {
  const hoursData = useContext(ProjectContext);
  const {
    projectFetchStatus,
    subProjectFetchStatus,
    projectData,
    subProjectData,
    setProjectStatus,
    setSubProjectStatus,
    setSubProjects,
    setProjects,
  } = hoursData;

  // Fetching main project data
  useEffect(() => {
    if (statusNull(projectFetchStatus)) {
      // Update status
      setProjectStatus(HttpRequestStatus.loading);

      // Fetch
      getProjectHours()
        .then((hours) => {
          setProjects(projectData.concat(hours));
        })
        .catch((err) => {
          console.log(err);
          setProjectStatus(HttpRequestStatus.error);
        });
    }
  }, [projectFetchStatus, projectData, setProjectStatus, setProjects]);

  // Fetching sub project data
  useEffect(() => {
    if (statusNull(subProjectFetchStatus)) {
      // Update status
      setSubProjectStatus(HttpRequestStatus.loading);

      // Fetch
      getSubProjectHours()
        .then((subHours) => {
          setSubProjects(subHours);
        })
        .catch((err) => {
          console.log(err);
          setSubProjectStatus(HttpRequestStatus.error);
        });
    }
  }, [
    subProjectFetchStatus,
    setSubProjectStatus,
    setSubProjects,
    subProjectData,
  ]);

  return (
    <div className="NSF-Overview">
      <h2>Hankkeet - Suuremmat projektit</h2>
      <div className="NSF-Row">
        {projectData.map((item) => (
          <DataCard
            key={`main-project-${item.projectHoursId}`}
            title={item.ProjectName}
            maxValue={item.BookedHours}
            value={item.ResourcedHours}
          />
        ))}
      </div>
      <h2>Pienprojektit - Pienet lisätyöt</h2>
      <div className="NSF-Row">
        {subProjectData.map((item) => (
          <DataCard
            key={`main-project-${item.subProjectHoursId}`}
            title={item.SubProjectName}
            maxValue={item.BookedHours}
            value={item.ResourcedHours}
          />
        ))}
      </div>
    </div>
  );
};

export default Overview;
