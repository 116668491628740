import React, { FunctionComponent } from "react";
import ProjectProvider from "./Hours";
import UserProvider from "./User";

const RootProvider: FunctionComponent = (props) => {
  return (
    <UserProvider>
      <ProjectProvider>{props.children}</ProjectProvider>
    </UserProvider>
  );
};

export default RootProvider;
