import React, { FunctionComponent } from "react";

interface Props extends React.HTMLProps<HTMLInputElement> {
  label?: string;
  errorText?: string;
}

const Input: FunctionComponent<Props> = (props) => {
  return (
    <label
      className={`NSF-Input ${props.className} ${
        props.errorText && "NSF-Input--Error"
      }`}
    >
      <span className="NSF-Input__label">{props.label}</span>
      <input {...props} />
      <span>{props.errorText ?? ""}</span>
    </label>
  );
};

export default Input;
