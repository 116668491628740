import React, { useContext } from "react";
import { UserContext } from "../../context/User";
import PercentageChart from "../../components/Chart/Chart";
import Overview from "../Overview/Overview";

export const Home = () => {
  const user = useContext(UserContext);

  return (
    <section className="NSF-PortalContent">
      <div className="NSF-Column">
        <Overview />
      </div>
    </section>
  );
};

export default Home;
