import React from "react";

import LoginForm from "../../components/LoginForm/LoginForm";
import Logo from "../../assets/images/logowhite.png";
import { FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa";

export const Login = () => {
  return (
    <section className="NSF-Login">
      <div className="NSF-Login__description">
        <img alt="NSF Logo" src={Logo} />
        <h1>Kirjaudu</h1>
        <p>
          Pidämme yrityksenne it-infrastruktuurin ajan tasalla uusimalla
          laitteita ja ohjelmistoja sekä tuomalla kehittyneempiä ratkaisuja
          saataville. Kaikissa projekteissa voit hankkia koko projektin tai
          haluamasi osan.
        </p>
        <div className="NSF-NaviCompany">
          <div className="NSF-NaviCompany__someList">
            <FaFacebookF />
            <FaTwitter />
            <FaLinkedinIn />
          </div>
        </div>
      </div>
      <LoginForm />
    </section>
  );
};

export default Login;
