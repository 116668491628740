import React, { FunctionComponent, useContext, useEffect } from "react";
import { RouteProps, Route, Redirect } from "react-router";
import { UserContext } from "../../context/User";
import { loginWithToken } from "../../core/api";
import {
  HttpRequestStatus,
  statusError,
  statusNull,
  statusReady,
} from "../../utils/http";

const AuthenticatedRoute: FunctionComponent<RouteProps> = (props) => {
  const { userFetchStatus, data, setUser, setStatus } = useContext(UserContext);

  useEffect(() => {
    if (statusNull(userFetchStatus)) {
      setStatus(HttpRequestStatus.loading);
      loginWithToken()
        .then((user) => {
          setUser(user);
          setStatus(HttpRequestStatus.ready);
        })
        .catch((err) => {
          console.error("Err", err);
          setStatus(HttpRequestStatus.error);
        });
    }
  }, [userFetchStatus, setStatus, setUser]);

  if (statusReady(userFetchStatus) && data) {
    return <Route {...props} />;
  }

  if (statusError(userFetchStatus)) {
    return <Redirect to="/" />;
  }

  return null;
};

export default AuthenticatedRoute;
