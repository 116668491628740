import React, { useContext } from "react";
import AccountInfo from "../../components/AccountInfo/AccountInfo";
import { UserContext } from "../../context/User";
import ChangePassword from "../../components/ChangePassword/ChangePassword";

export const AccountPage = () => {
  const user = useContext(UserContext);

  return (
    <section className="NSF-AccountPage">
      <h1>Oma NSF tilisi</h1>
      <div className="NSF-Row">
        <AccountInfo user={user.data!} />
        <ChangePassword />
      </div>
    </section>
  );
};

export default AccountPage;
