import React, { useContext } from "react";
import { UserContext } from "../../context/User";

const Header = () => {
  const user = useContext(UserContext);

  return (
    <div className="NSF-Header">
      <div className="NSF-Header__textBlock">
        <h2>Tervetuloa {user.data && user.data.displayName}!</h2>
        <h3>NSF Asiakaspalveluportaali</h3>
      </div>
    </div>
  );
};

export default Header;
