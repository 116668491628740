import React, { FunctionComponent } from "react";
import { HttpRequestStatus } from "../utils/http";

interface Context {
  userFetchStatus: HttpRequestStatus;
  data: User | null;

  // Fucntions for mutating Context
  setStatus: (status: HttpRequestStatus) => void;
  setUser: (user: User) => void;
  clearState: () => void;
}

// Default context is used to initialize the context-slice.
const defaultState = {
  userFetchStatus: HttpRequestStatus.null,
  data: null,
  setUser: () => {
    throw Error("SetUser has not been initialized correctly");
  },
  setStatus: () => {
    throw Error("SetStatus has not been initialized correctly");
  },
  clearState: () => {
    throw Error("SetStatus has not been initialized correctly");
  },
};

export const UserContext = React.createContext<Context>(defaultState);
export const UserConsumer = UserContext.Consumer;

const UserProvider: FunctionComponent = (props) => {
  const [state, setState] = React.useState<Context>(defaultState);

  const setUser = (user: User) => {
    setState((prev) => {
      return { ...prev, data: user, userFetchStatus: HttpRequestStatus.ready };
    });
  };

  const setStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, userFetchStatus: status };
    });
  };

  const clearState = () => {
    setState(() => {
      return initState;
    });
  };

  const initState = {
    ...state,
    setUser,
    setStatus,
    clearState,
  };

  return (
    <UserContext.Provider value={initState}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
