import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import UserProvider, { UserConsumer } from "./context/User";
import Home from "./pages/home/home";
import AuthenticatedRoute from "./components/AuthenticatedRoute/AuthenticatedRoute";
import Login from "./pages/login/login";
import AccountPage from "./pages/AccountPage/AccountPage";
import RootProvider from "./context/Root";

const App = () => {
  return (
    <Router>
      <RootProvider>
        <div className="NSF-Container">
          <Switch>
            <AuthenticatedRoute path="/portal">
              <div className="NSF-Layout">
                <Sidebar />
                <div className="NSF-App">
                  <Header />
                  <div className="NSF-Portal">
                    <Route exact path="/portal" component={Home} />
                    <Route
                      exact
                      path="/portal/account"
                      component={AccountPage}
                    />
                  </div>
                </div>
              </div>
            </AuthenticatedRoute>
            <UserConsumer>
              {(value) => (
                <Route
                  path="/"
                  render={() => {
                    const loggedIn = !!value.data;
                    if (loggedIn) {
                      return <Redirect to="/portal" />;
                    }
                    return <Login />;
                  }}
                />
              )}
            </UserConsumer>
          </Switch>
        </div>
      </RootProvider>
    </Router>
  );
};

export default App;
