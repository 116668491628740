import React, { FunctionComponent } from "react";
import { HttpRequestStatus } from "../utils/http";

interface Context {
  projectFetchStatus: HttpRequestStatus;
  subProjectFetchStatus: HttpRequestStatus;
  projectData: ProjectHoursData[];
  subProjectData: SubProjectHoursData[];

  // Fucntions for mutating Context
  setProjectStatus: (status: HttpRequestStatus) => void;
  setSubProjectStatus: (status: HttpRequestStatus) => void;
  setProjects: (projectHours: ProjectHoursData[]) => void;
  setSubProjects: (Project: SubProjectHoursData[]) => void;
  clearState: () => void;
}

const defaultFn = () => {
  throw Error("ContextFn has not been initialized correctly");
};

// Default context is used to initialize the context-slice.
const defaultState: Context = {
  projectFetchStatus: HttpRequestStatus.null,
  subProjectFetchStatus: HttpRequestStatus.null,
  projectData: [],
  subProjectData: [],
  setSubProjectStatus: defaultFn,
  setProjectStatus: defaultFn,
  setProjects: defaultFn,
  setSubProjects: defaultFn,
  clearState: defaultFn,
};

export const ProjectContext = React.createContext<Context>(defaultState);
export const ProjectConsumer = ProjectContext.Consumer;

const ProjectProvider: FunctionComponent = (props) => {
  const [state, setState] = React.useState<Context>(defaultState);

  const setProjects = (projectData: ProjectHoursData[]) => {
    setState((prev: Context) => {
      return {
        ...prev,
        projectData: projectData,
        projectFetchStatus: HttpRequestStatus.ready,
      };
    });
  };

  const setSubProjects = (projectHours: SubProjectHoursData[]) => {
    setState((prev: Context) => {
      return {
        ...prev,
        subProjectData: projectHours,
        subProjectFetchStatus: HttpRequestStatus.ready,
      };
    });
  };

  const setProjectStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, projectFetchStatus: status };
    });
  };

  const setSubProjectStatus = (status: HttpRequestStatus) => {
    setState((prev: Context) => {
      return { ...prev, subProjectFetchStatus: status };
    });
  };

  const clearState = () => {
    setState(() => {
      return initState;
    });
  };

  const initState: Context = {
    ...state,
    setProjects,
    setSubProjects,
    setProjectStatus,
    setSubProjectStatus,
    clearState,
  };

  return (
    <ProjectContext.Provider value={initState}>
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
