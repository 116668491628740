import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";

const rootEl = document.getElementById("root");

const render = () => ReactDOM.render(<App />, rootEl);

render();
