import React, { FunctionComponent } from "react";
import { Doughnut } from "@reactchartjs/react-chart.js";

interface Props {
  amount: number;
  maximum: number;
}

const PercentageChart: FunctionComponent<Props> = (props) => {
  const percentage = Math.round((props.amount / props.maximum) * 100);

  const data = {
    datasets: [
      {
        label: "Laskutetut tunnit",
        data: [percentage, 100 - percentage],
        backgroundColor: ["#FF7F00"],
        borderColor: ["rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const plugins = [
    {
      beforeInit: (chart: any) => {
        const dataset = chart.data.datasets[0];
        chart.data.labels = [dataset.label];
        dataset.data = [dataset.percent, 100 - dataset.percent];
      },
    },
    {
      beforeDraw: (chart: any) => {
        const width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;
        ctx.restore();
        const fontSize = (height / 150).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.fillStyle = "#9b9b9b";
        ctx.textBaseline = "middle";
        const percent = chart.data.datasets[0].data[0];
        const text = percent + "%",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 85,
    rotation: (3 * Math.PI) / 2,
    legend: {
      display: false,
      reverse: true,
    },
    tooltips: {
      filter: (tooltipItem: any) => tooltipItem.index === 0,
    },
  };

  return (
    <div style={{ width: "100px", height: "100px" }}>
      <Doughnut
        type="doughnut"
        data={data}
        options={options}
        plugins={plugins}
      />
    </div>
  );
};

export default PercentageChart;
