import React from "react";

interface Props {
  user: User;
}

const AccountInfo = (props: Props) => {
  const { user } = props;
  return (
    <div className="NSF-AccountInfo">
      <h3>
        <b>Name:</b> {user.displayName}
      </h3>
      <h3>
        <b>Email:</b> {user.email}
      </h3>
      <h3>
        <b>Business Code:</b> {user.businessCode}
      </h3>
    </div>
  );
};

export default AccountInfo;
