import React from "react";
import BrandBlock from "./BrandBlock";
import Navigation from "./Navigation";

const Sidebar = () => {
  return (
    <div className="NSF-Sidebar">
      <BrandBlock />
      <Navigation />
    </div>
  );
};

export default Sidebar;
