import React, { useContext, useState } from "react";

import Input from "../Input/Input";
import { UserContext } from "../../context/User";
import { loginUser } from "../../core/api";
import { HttpRequestStatus, statusLoading } from "../../utils/http";
import { storeToken } from "../../utils/browser";

interface FormState {
  email: string;
  password: string;
  cache: boolean;
}

const defaultFormState = {
  email: "",
  password: "",
  cache: false,
};

const ValidatedLoginForm = () => {
  const [submitStatus, setSubmitStatus] = useState(HttpRequestStatus.null);
  const [formState, setState] = useState<FormState>(defaultFormState);

  const { setUser, setStatus } = useContext(UserContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setSubmitStatus(HttpRequestStatus.loading);
    setStatus(HttpRequestStatus.loading);
    const { email, password, cache } = formState;
    try {
      const user = await loginUser(email, password);
      setSubmitStatus(HttpRequestStatus.ready);
      storeToken(user.jwtToken as string, cache)
      setUser(user);
    } catch (err) {
      setState({ ...defaultFormState, cache });
      setSubmitStatus(HttpRequestStatus.error);
    }
  };

  return (
    <form className="NSF-LoginForm" onSubmit={onSubmit}>
      <Input
        id="email"
        className="NSF-LoginForm__input"
        label="Sähköposti"
        type="email"
        onChange={handleChange}
        placeholder="Sähköposti"
      />
      <Input
        className="NSF-LoginForm__input"
        id="password"
        label="Salasana"
        type="password"
        onChange={handleChange}
        placeholder="salasana"
      />
      <label className="NSF-LoginForm__checkBox">
        <input
          onChange={handleChange}
          id="cache"
          type="checkbox"
          name="rememberMe"
        />
        Muista minut
      </label>
      <button
        disabled={statusLoading(submitStatus)}
        className="NSF-LoginForm__button"
        onClick={(e) => handleChange(e as any)}
      >
        Kirjaudu
      </button>
    </form>
  );
};

export default ValidatedLoginForm;
