import { get, post } from "./httpClient";

export const healtCheck = () => {
  return get<User>("/v1/health");
}

export const loginUser = (email: string, password: string) => {
  return post<User>("/v1/auth/login", { email, password });
};

export const loginWithToken = () => {
  return get<User>("/v1/auth/refresh");
};

export const changePassword = (oldPassword: string, password: string) => {
  return post<User>("/v1/auth/reset-password", { oldPassword, password });
};

export const getProjectHours = () => {
  return get<ProjectHoursData>("/v1/project-hours/projects");
};

export const getSubProjectHours = () => {
  return get<SubProjectHoursData[]>("/v1/project-hours/sub-projects");
};
