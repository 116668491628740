import React, { useState } from "react";
import Input from "../Input/Input";
import { changePassword } from "../../core/api";
import { HttpRequestStatus, statusLoading } from "../../utils/http";

interface FormState {
  oldPassword: string;
  repeatPassword: string;
  newPassword: string;
}

const defaultFormState = {
  oldPassword: "",
  repeatPassword: "",
  newPassword: "",
};

enum FormErrors {
  none,
  missMatch,
  badPassword,
}

const ChangePassword = () => {
  const [submitStatus, setSubmitStatus] = useState(HttpRequestStatus.null);
  const [formState, setState] = useState<FormState>(defaultFormState);
  const [formError, setFormError] = useState(FormErrors.none);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.stopPropagation();
    setSubmitStatus(HttpRequestStatus.loading);
    const { repeatPassword, oldPassword, newPassword } = formState;
    if (oldPassword !== repeatPassword) {
      return;
    }
    try {
      await changePassword(oldPassword, newPassword);
      setSubmitStatus(HttpRequestStatus.ready);
    } catch (err) {
      setState(defaultFormState);
      setSubmitStatus(HttpRequestStatus.error);
    }
  };

  return (
    <form className="NSF-ChangePassword" onSubmit={onSubmit}>
      <h2>Vaihda tilisi salasana</h2>
      <p>
        Tilin salasana tulisi vaihtaa ainakin ensimmäisen kirjautumisen jälkeen.
      </p>
      <Input
        id="oldPassword"
        label="Vanha salasana"
        type="password"
        onChange={handleChange}
        placeholder="Vanha salasana"
      />
      <div className="NSF-ChangePassword__divider" />
      <Input
        id="newPassword"
        label="Uusi salasana"
        type="password"
        onChange={handleChange}
        placeholder="Uusi salasana"
      />
      <Input
        id="repeatPassword"
        label="Toista uusi salasana"
        type="password"
        onChange={handleChange}
        placeholder="Toista salasana"
      />

      <button
        disabled={statusLoading(submitStatus)}
        className="NSF-ChangePassword__button"
        type="submit"
      >
        Vaihda salasana
      </button>
    </form>
  );
};

export default ChangePassword;
