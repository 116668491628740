import React from "react";

import { NavbarBrand } from "reactstrap";
import { NavLink as Link } from "react-router-dom";

import Logo from "../../assets/images/logowhite.png";

export const BrandIcon = (props: any) => <img src={Logo} alt="Logo" />;

const BrandBlock = (props: any) => (
  <div className="NSF-BrandBlock">
    <NavbarBrand tag={Link} to="/portal">
      <BrandIcon />
    </NavbarBrand>
  </div>
);

export default BrandBlock;
