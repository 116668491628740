export const tokenKey = "nsf_jwt";

/*** TODO:
 * Storing JWT in localStorage and sessionStorage isn't completely secure
 *
 * We should look into replacing this means of token storage with a system
 * that utilizes http-only cookies instead. Reading http-only cookies is not
 * possible in such an easy manner as is the case with local and session storages.
 */

export const storeToken = (jwt: string, cache: boolean = false) => {
  sessionStorage.setItem(tokenKey, jwt);

  // User wants to store the received JWT-token for next login
  if (cache) {
    localStorage.setItem(tokenKey, jwt);
  }
};

export const readToken = () => {
  const sessionToken = sessionStorage.getItem(tokenKey);
  const cachedToken = localStorage.getItem(tokenKey);

  // Session token exists, but cachedToken is an expired token
  // => Remove cachedToken to replace on next login.
  if (sessionToken && sessionToken !== cachedToken) {
    localStorage.removeItem(tokenKey);
  }

  // Session token doesn't exist, but user has a cached token
  // => Return cachedToken instead
  if (!sessionToken && cachedToken) {
    return cachedToken;
  }

  return sessionToken;
};
